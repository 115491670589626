import { atom, useSetAtom } from 'jotai'

export const isSpinnerShownAtom = atom(false)

export const spinnerElementAtom = atom<JSX.Element | null>(null)

// Writeable atom to handle showing/hiding the spinner
export const spinnerActionsAtom = atom(
  null,
  (get, set, { show, element }: { show: boolean; element?: JSX.Element | null }) => {
    set(isSpinnerShownAtom, show)
    set(spinnerElementAtom, show ? element || null : null)
  }
)

export function useOverlaySpinner() {
  const setSpinner = useSetAtom(spinnerActionsAtom)
  return {
    setSpinner,
  }
}
