import { useEffect, useCallback } from 'react'
import { MemberData } from '@/modules/core/contexts/membershipAuth'
import { isTenant } from '@/utils/config'

import { OrderCompleted } from '@/generated/analytics'
import { ConversionActionEvent } from './interfaces'
declare global {
  interface Window {
    ire: (...args: any) => void
  }
}

const impactUniversalTrackingTag = `
(function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};f=d.createElement(b);g=d.getElementsByTagName(b)[0];f.async=1;f.src=a;g.parentNode.insertBefore(f,g);})('//d.impactradius-event.com/A2418572-9a1d-44c3-a1ba-e8780964b8a41.js','script','ire',document,window);
`
const IMPACT_SAMPLE_ACTION_ID = 22099
const IMPACT_NO_SAMPLE_ACTION_ID = 22100

function encodeEmail(email: string): string {
  const regex = new RegExp(/[^@.]/gm)
  return email.replace(regex, '*')
}

const identifyUserToImpact = (userId: string, email: string) => {
  try {
    if (isTenant('ww')) return
    const customerId = userId
    const customerEmail = encodeEmail(email || '')
    window.ire('identify', { customerId, customerEmail })
  } catch (e) {
    console.log(e)
  }
}

const useImpactIdentify = (id = '', email = '') => {
  useEffect(() => {
    identifyUserToImpact(id, email)
  }, [email, id])
}

const useImpactIdentifyCallback = (member: MemberData | null) => {
  const email = member?.email || ''
  const id = member?.id || ''

  return useCallback(() => {
    identifyUserToImpact(id, email)
  }, [id, email])
}

const trackConversionToImpact = (data: OrderCompleted, customerId: string, customerStatus: string) => {
  if (isTenant('ww')) return

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const hasSamples = data.products.some((product) => product.sample)
    const actionTrackerId = hasSamples ? IMPACT_SAMPLE_ACTION_ID : IMPACT_NO_SAMPLE_ACTION_ID
    const conversionActionEvent: ConversionActionEvent = {
      orderId: data.order_id,
      customerId: customerId,
      //@ts-expect-error customerEmail is not defined in the interface
      customerEmail: encodeEmail(data.email || ''),
      customerStatus: customerStatus,
      currencyCode: data.currency,
      orderPromoCode: data.coupon,
      orderDiscount: data.discount,
      items: data.products.map((product) => ({
        subTotal: product.price,
        category: product.category,
        // sku parameter has been omitted on foundation
        sku: '',
        quantity: product.quantity,
        name: product.name,
      })),
    }

    window.ire('trackConversion', actionTrackerId, conversionActionEvent)
  } catch (err) {
    console.error(err)
  }
}

export {
  identifyUserToImpact,
  useImpactIdentify,
  impactUniversalTrackingTag,
  useImpactIdentifyCallback,
  trackConversionToImpact,
}
