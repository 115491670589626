import { ReactNode, createContext, useContext } from 'react'
import { PromotionList } from './promotions.schema'
// eslint-disable-next-line import/no-restricted-paths
import type { PriceTier } from '../../product/shared/product.schema'
import { getAmount, getNewTier, getSavings } from './promotions.utils'

const Context = createContext<PromotionList>([])

interface PromotionsProviderProps {
  promotions: PromotionList
  children: ReactNode
}

export function PromotionsProvider({ promotions, children }: PromotionsProviderProps) {
  return <Context.Provider value={promotions}>{children}</Context.Provider>
}

interface UsePromotionArgs {
  price: PriceTier
}
export function usePromotion({ price: oldTier }: UsePromotionArgs) {
  const promotions = useContext(Context)

  const now = new Date().getTime()

  /**
   * currently, we just need to find the first active promotion
   */
  const current =
    promotions.find((promotion) => {
      const startDate = new Date(promotion.startDate)
      const endDate = new Date(promotion.endDate)
      console.log(now >= startDate.getTime() && now <= endDate.getTime())
      return now >= startDate.getTime() && now <= endDate.getTime()
    }) || null

  const newTier = current ? getNewTier(current, oldTier) : null

  const savings = current ? getSavings(current, oldTier.totalAmount) : 0

  const amount = current ? getAmount(current) : ''

  return {
    promotion: current,
    oldTier,
    newTier,
    savings,
    amount,
  }
}
