import { storefront } from '@/domains/core/storefront'
import lru from '@/lib/lru'
import { Menu, TreeItem } from './subnav.schema'

export type GetSubnavParams = {
  menuCode: string
}

export async function getSubnav(params: GetSubnavParams) {
  const { menuCode } = params
  const cache = lru.get(menuCode)

  if (cache) {
    return cache
  }

  const data = await storefront.getMenu({
    menuCode,
  })

  const menuStructure = Menu.safeParse(data.menu)

  if (menuStructure.success) {
    lru.set(menuCode, menuStructure.data)
    return menuStructure.data
  } else {
    throw new Error(`Invalid menu structure: ${menuStructure.error.message}`)
  }
}
