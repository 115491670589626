// eslint-disable-next-line import/no-restricted-paths
import type { PriceTier, ProductBadge } from '../../product/shared/product.schema'
import { Promotion } from './promotions.schema'

export function toCurrency(price: number) {
  return `$${price.toFixed(2)}`
}

function calculatePercentageDiscount(price: number, discount: number) {
  if (discount > 100 || discount < 0) {
    throw new Error('Discount must be between 0 and 100')
  }

  const discountAmount = price * (discount / 100)

  return price - discountAmount
}

export function getDiscount(promotion: Pick<Promotion, 'type' | 'amount'>, price: number) {
  switch (promotion.type) {
    case 'percentage':
    default:
      return calculatePercentageDiscount(price, promotion.amount)
  }
}

export function getAmount(promotion: Pick<Promotion, 'type' | 'amount'>) {
  switch (promotion.type) {
    case 'percentage':
    default:
      return `$${promotion.amount}`
  }
}

export function getSavings(promotion: Pick<Promotion, 'type' | 'amount'>, oldPrice: number) {
  return oldPrice - getDiscount(promotion, oldPrice)
}

export function getNewTier(promotion: Pick<Promotion, 'type' | 'amount'>, price: PriceTier): PriceTier {
  return {
    ...price,
    totalAmount: getDiscount(promotion, price.totalAmount),
    unitAmount: getDiscount(promotion, price.unitAmount),
  }
}

export function translateDiscount(promotion: Promotion): string {
  switch (promotion.type) {
    case 'percentage':
    default:
      return `-${promotion.amount}%`
  }
}

export function getBadge(promotion: Promotion): ProductBadge {
  return {
    name: translateDiscount(promotion),
    background: 'var(--varPrimary300)',
    textColor: 'var(--varNeutralWhite)',
  }
}

export function translatePrice(price?: number) {
  return `${toCurrency(price || 0)} ea.`
}

/**
 * Translate quantity to a string i.e. (100qty)
 * @param quantity
 * @returns
 */
export function translateQuantity(quantity?: number): string {
  return `(${quantity}qty)`
}
