import type { PromotionList } from './promotions.schema'

export const PROMOTIONS: PromotionList = [
  {
    name: '25% off orders',
    code: 'TAKE25',
    type: 'percentage',
    amount: 25,
    startDate: String(process.env.APP_STRIKETHROUGH_PRICE_START_DATE),
    endDate: String(process.env.APP_STRIKETHROUGH_PRICE_END_DATE),
    productTypeCodes: [],
  },
]
