import Honeybadger from '@honeybadger-io/js'
import Cookie from 'cookie'

import {
  Experiment,
  ExperimentInfo,
  ExperimentInformation,
  ExperimentServiceOptions,
  VariantInformation,
} from './flipper.types'

import { getFromAPI } from './flipper.client'

import { isTenant } from '@/utils/config'
import { IncomingMessage } from 'http'
import {
  filtering,
  fullStoryExisting,
  fullStoryNew,
  pdpDesktopCarouselV2,
  pricingFilterPosition,
  reviewPrice,
  strikeThrough,
} from './Flipper.constants'

let GUEST_COOKIE = 'ajs_anonymous_id'

let MEMBER_COOKIE = 'ajs_user_id'

let FLIPPER_COOKIE = 'flipperExperiments'

let COOKIE_DOMAIN = String(process.env.APP_DOMAIN)

let COOKIE_PATH = String(process.env.APP_BASEPATH)

let EXPERIMENTS = [
  fullStoryExisting,
  fullStoryNew,
  filtering,
  pdpDesktopCarouselV2,
  reviewPrice,
  pricingFilterPosition,
  strikeThrough,
]

let experimentService: ExperimentService

export class ExperimentService {
  headers: IncomingMessage['headers'] | undefined = undefined
  route = ''
  realPath?: string = ''
  skipExperiments = new Set<string>()
  private variants = new Map<string, ExperimentInfo>()
  public variantsCookie = ''
  public anonymousIdCookie = ''

  private get cookies() {
    if (!this.headers) return
    return Cookie.parse(this.headers?.['cookie'] || '') || {}
  }

  private get isMobileUserAgent() {
    return this.headers?.['user-agent']?.includes('Mobile') || false
  }

  private get isUserAgentBot() {
    const BOT_USER_AGENT_PATTERNS = [
      /googlebot/i,
      /bing/i,
      /bot/i,
      /slurp/i,
      /baidu/i,
      /duckduck/i,
      /spider/i,
      /preview/i,
      /QATT/i,
    ]
    return BOT_USER_AGENT_PATTERNS.some((re) => re.test(this.headers?.['user-agent'] || ''))
  }

  private get isPrefetch() {
    return (this.headers?.['purpose'] === 'prefetch' || this.headers?.['purpose']?.includes('prefetch')) ?? false
  }

  private get isAsset() {
    return this.realPath?.includes('_next/') ?? false
  }

  private get experiments() {
    return EXPERIMENTS
  }

  private get activeExperiments(): Experiment[] {
    return this.experiments.filter(
      (experiment) =>
        this.isInSkipList(experiment) &&
        this.filterByRoute(experiment) &&
        this.filterByIdentifier(experiment) &&
        this.filterByTenant(experiment)
    )
  }

  private isInSkipList = (experiment: Experiment) => !this.skipExperiments.has(experiment.id)

  private filterByRoute = (experiment: Experiment) => experiment.routes.test(this.route)

  private filterByIdentifier = (experiment: Experiment) => {
    return !!this.getBucketingId(experiment.identifier)
  }

  private filterByTenant = (experiment: Experiment) => experiment.tenant === 'both' || isTenant(experiment.tenant)

  private getBucketingId(identifier: Experiment['identifier']) {
    let cookie: string = this.cookies[GUEST_COOKIE]

    switch (identifier) {
      case 'guest':
        cookie = this.cookies[GUEST_COOKIE] || this.headers?.['x-anon-id']
        break
      case 'member':
        cookie = this.cookies[MEMBER_COOKIE]
        break
    }

    return (cookie || '').replace(/%22|"/g, '')
  }

  /**
   * Variants
   */

  /**
   * Generates a Map from all experiments already set in Cookies
   * @returns
   */
  private generateVariants() {
    this.variants.clear()
    if (!this.cookies[FLIPPER_COOKIE]) return
    const flipperCookie = JSON.parse(this.cookies[FLIPPER_COOKIE]) as Record<string, ExperimentInfo>

    //workaround for PAPER-14135 - preserve in cookie others experiments - delete when fixed
    //if (!this.isFlipperCookieValid(flipperCookie)) return

    Object.entries(flipperCookie).map(([k, v]) => {
      if (this.skipExperiments.has(k)) return
      this.variants.set(k, v)
    })
  }

  private async generateVariant(
    experiment: Experiment,
    variant?: ExperimentInfo
  ): Promise<[string, ExperimentInfo] | undefined> {
    const { identifier, id: experimentId } = experiment
    const bucketingId = this.getBucketingId(identifier)

    if (!bucketingId || !experimentId) return

    try {
      let variantURL = `experiments/${experimentId}/${bucketingId}`

      if (variant) {
        await getFromAPI<VariantInformation>(`assignments/${experimentId}/${bucketingId}`).catch(() => {
          variantURL = variantURL + `?variantId=${variant.variantId}`
        })
      }

      const variantInformation = await getFromAPI<VariantInformation>(variantURL)
      const experimentInformation = await getFromAPI<ExperimentInformation>(`experiments/${experimentId}`)

      if (!variantInformation || !experimentInformation) {
        return
      }

      const variantId = variantInformation?.variantId || 'default'
      const variantName = variantInformation?.variantName || 'default'
      const experimentName = experimentInformation?.experimentName
      const firstTrigger = !variant

      return [
        experimentId,
        {
          variantId,
          bucketingId,
          firstTrigger,
          extraData: { experimentId, variantName, experimentName },
        },
      ]
    } catch (error) {
      Honeybadger.notify('FlipperService::generateVariant', {
        context: {
          name: experiment.name,
          experimentId,
          bucketingId,
          error,
        },
      })
    }
  }

  private addCookies(name: string, value: Map<string, ExperimentInfo>, path?: string) {
    const cookieValue = Array.from(value).reduce((acc, [k, v]) => {
      return {
        ...acc,
        [k]: {
          bucketingId: v.bucketingId,
          variantId: v.variantId,
        },
      }
    }, {} as Record<string, ExperimentInfo>)

    return Cookie.serialize(name, JSON.stringify(cookieValue), {
      secure: false,
      httpOnly: false,
      ignoreErrors: true,
      domain: COOKIE_DOMAIN,
      path: path || COOKIE_PATH,
      maxAge: 60 * 60 * 24 * 365, // 1 year
      sameSite: 'Lax',
      encoding: 'none',
    })
  }

  private shouldAssign(experiment: Experiment, variant: ExperimentInfo) {
    return this.getBucketingId(experiment.identifier) !== variant.bucketingId
  }

  private async setExperiments() {
    this.generateVariants()

    for (const experiment of this.activeExperiments) {
      const variant = this.variants.get(experiment.id)

      if (!variant || this.shouldAssign(experiment, variant)) {
        const newExperiment = await this.generateVariant(experiment, variant)

        if (newExperiment) {
          this.variants.set(...newExperiment)
        } else {
          this.variants.delete(experiment.id)
        }
      } else {
        this.variants.set(experiment.id, {
          ...variant,
          firstTrigger: false,
        })
      }
    }
  }

  /**
   * Allows to skip experiment based on runtime conditions
   * Useful for 3rd party integrations with Flipper
   * @param ids : string[] List of experiments id that should be skipped
   */
  addToSkipList(ids: string[]) {
    ids.filter(Boolean).forEach((id) => this.skipExperiments.add(id))
  }

  async initialize(headers: IncomingMessage['headers'] | undefined, route: string, realPath?: string) {
    try {
      this.headers = headers
      this.route = route
      this.realPath = realPath

      if (!this.activeExperiments.length || this.isUserAgentBot || this.isPrefetch || this.isAsset) return

      await this.setExperiments()

      if (!this.variants.size) return

      this.variantsCookie = this.addCookies(FLIPPER_COOKIE, this.variants)

      return Object.fromEntries(this.variants)
    } catch (e) {
      console.log(e)
    }
  }

  static experiments() {
    return EXPERIMENTS
  }

  static cookies(): Record<string, ExperimentInfo> {
    if (typeof window !== 'undefined') {
      const cookies = Cookie.parse(document.cookie)
      return cookies[FLIPPER_COOKIE] ? JSON.parse(cookies[FLIPPER_COOKIE]) : {}
    }
    return {}
  }

  static forRoot(opts: ExperimentServiceOptions) {
    const { experiments, cookieDomain, cookiePath, guestCookie, memberCookie, flipperCookie } = opts
    EXPERIMENTS = experiments || EXPERIMENTS
    GUEST_COOKIE = guestCookie || GUEST_COOKIE
    MEMBER_COOKIE = memberCookie || MEMBER_COOKIE
    FLIPPER_COOKIE = flipperCookie || FLIPPER_COOKIE
    COOKIE_DOMAIN = cookieDomain || COOKIE_DOMAIN
    COOKIE_PATH = cookiePath || COOKIE_PATH

    experimentService = new ExperimentService()
    experimentService.variantsCookie = ''
    return experimentService
  }
}
