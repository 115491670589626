import { useTracking } from '@/components/Tracking/useTracking/useTracking'
import { guestBooks } from '@/data/updatedCategoryHierarchyData'
import { Draft } from '@/domains/draft/draft.schema'
import typewriter from '@/generated/analytics'
import * as Segment from '@/generated/analytics/segment'
import { CartDetailsItem, OptionValue } from '@/generated/graphql/graphql'
import { trackInteraction } from '@/modules/core/services/analytics'
import { Product, Variation } from '@/services/algolia'
import { buildImageURL } from '@/ui/Image/Image'
import { createAnalyticsVariant } from '@/utils/analytics/helpers'
import { AnalyticsUserData } from '@/utils/analytics/interfaces'
import { getCookieByName, getProductFormats, removeQueryParamsFromPathUrl } from '@/utils/helpers'
import { useCatalogAnalytics } from '.'

const PRODUCT_PATH = process.env.APP_PRODUCT_PATH || ''

const mergeSampleFields = (product: Product, variation: Variation) => ({
  ...product,
  variationId: variation.id,
  paperType: variation?.paperType,
  foilId: variation?.foil?.id,
})

const createAnalyticsUserData = (): AnalyticsUserData => {
  const userId = getCookieByName(window.document?.cookie, 'ajs_user_id') || 'unidentified'
  const anonymousId = getCookieByName(window.document?.cookie, 'ajs_anonymous_id') || 'unidentified'
  return {
    userId: userId.replace(/["]/g, '') ?? '',
    anonymousId: anonymousId.replace(/["]/g, '') ?? '',
  }
}

const createAnalyticsIntegrations = (): Segment.Options => {
  const gaCookie = getCookieByName(window.document?.cookie, '_ga').split('.').slice(-2).join('.')
  return {
    integrations: {
      ['Google Universal Analytics']: {
        ['clientId']: gaCookie,
      },
    },
  }
}

const createAnalyticsUrl = (slug: string) => {
  if (!slug) return ''
  return `${process.env.APP_PAPER_HOST}${process.env.APP_BASEPATH}${PRODUCT_PATH}/${removeQueryParamsFromPathUrl(slug)}`
}

const createAnalyticsDraft = (draft: Draft, location: string, item: CartDetailsItem, position = 0) => {
  const optionValues = draft.variation.optionValues as OptionValue[]
  const productCode = item?.variation?.product.productFormat.name || ''
  const isPersonalizedSample = (draft.variation.isSample && !!draft.portfolioItem?.customizer) || undefined

  return {
    category: draft.variation.catalogProduct.catalogProductType.name,
    image_url: draft.image || '',
    name: draft.variation.catalogProduct.name,
    price: item.price?.basePrice || 0,
    position: position,
    product_id: draft.variation.catalogProduct.externalId,
    quantity: draft.quantity,
    sample_details: [{ is_sample: draft.variation.isSample, personalized: isPersonalizedSample }],
    sku: draft.variation.id,
    location: location,
    url: createAnalyticsUrl(item?.slug || ''),
    variant: createAnalyticsVariant(optionValues, productCode),
    hasDesign: !!draft.portfolioItem.notes,
  }
}

type ProductData = {
  product: Product
  selectedVariant: Variation
  imageUri: string
  isSample: boolean
  position?: number
}

const createAnalyticsProduct = ({ position = 0, ...productData }: ProductData) => {
  const { product, selectedVariant, imageUri, isSample } = productData
  const format = getProductFormats(product)
  const category = Array.isArray(product.categoryName)
    ? product.categoryName[product.categoryName.length - 1]
    : product.categoryName
  const quantity = isSample || category === guestBooks.name ? 1 : 100

  return {
    position,
    price: product.unitPriceAt100 || NaN,
    product_id: product.id, // catalogProductId
    sku: selectedVariant.id,
    category,
    name: product.name,
    quantity,
    image_url: imageUri,
    sample_details: [{ is_sample: isSample, personalized: isSample ? false : undefined }],
    variant: [
      {
        color: selectedVariant.colorway?.name,
        material: selectedVariant.paperType?.name,
        format,
        foil_color: selectedVariant.foil?.name,
        paper_color: selectedVariant.paperColor?.name,
        flap_type: selectedVariant.flapType?.name,
      },
    ],
    url: createAnalyticsUrl(product.slug),
  }
}
const analyticsProductsHelper = (products: Product[]) =>
  products.map((product, i) =>
    createAnalyticsProduct({
      product,
      isSample: false,
      selectedVariant: product.variations[0],
      imageUri: buildImageURL(product.variations[0].heroImagePath?.slice(1) || ''),
      position: i,
    })
  )

const useTrackProductsViewed = () => {
  const { location, category } = useCatalogAnalytics()
  const trackingData = useTracking()

  return (products: Product[], listId: string) => {
    const analyticsProductsList = analyticsProductsHelper(products)

    const options = {
      category,
      location,
      products: analyticsProductsList,
      list_id: listId,
      ...trackingData,
    }
    trackInteraction('Product List Viewed', options)
  }
}

export {
  analyticsProductsHelper,
  createAnalyticsDraft,
  createAnalyticsIntegrations,
  createAnalyticsProduct,
  createAnalyticsUrl,
  createAnalyticsUserData,
  mergeSampleFields,
  typewriter,
  useTrackProductsViewed,
}
