import { isTenant } from '@/utils/config'
import { useAtom } from 'jotai'
import dynamic from 'next/dynamic'
import { isSpinnerShownAtom, spinnerElementAtom } from './overlaySpinner.atom'
import OverlaySpinnerTK from './tk'
import OverlaySpinnerWW from './ww'

const OverlayModal = dynamic(() => import('./OverlayModal').then((m) => m.OverlayModal))

const Spinner = isTenant('ww') ? OverlaySpinnerWW : OverlaySpinnerTK
export const OverlaySpinner = () => {
  const [isShown] = useAtom(isSpinnerShownAtom)
  const [element] = useAtom(spinnerElementAtom)

  if (!isShown) return null

  return element ? <OverlayModal>{element}</OverlayModal> : <Spinner />
}
