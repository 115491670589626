import Overlay from '@xo-union/tk-component-overlay'
import Spinner from '@xo-union/tk-component-spinner'

import styles from './overlaySpinnerTK.module.scss'

const OverlaySpinnerTK = () => (
  <Overlay className={styles.overlay} color="light" data-testid="overlay-spinner-tk">
    <Spinner className={styles.overlaySpinner} />
  </Overlay>
)

export default OverlaySpinnerTK
