import { GraphQLClient } from 'graphql-request'
import { getSdk } from './generated'

const URL = process.env.INTERNAL_STOREFRONT_API_URL ?? (process.env.APP_STOREFRONT_API_URL as string)

const client = new GraphQLClient(URL, {
  credentials: 'include',
  headers: {
    'x-tenant': process.env.APP_BRAND as string,
  },
})

export const storefront = getSdk(client)
