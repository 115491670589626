import styles from './overlaySpinnerWW.module.scss'

const OverlaySpinnerWW = () => (
  <div className={styles.mask} data-testid="overlay-spinner-ww">
    <div className={styles.wrapper}>
      <span
        className={styles.wrapperBefore}
        style={{
          backgroundImage: `url(${process.env.APP_MEDIA_API}/images/86cd24d3-6963-4043-a3f4-696b6c903335)`,
        }}
      />
      <div className={styles.loader}></div>
    </div>
  </div>
)

export default OverlaySpinnerWW
