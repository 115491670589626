import HB from '@honeybadger-io/js'

const IGNORED_MESSAGES = ['Unauthorized Exception']

HB.configure({
  apiKey: process.env.APP_HONEYBADGER_API_KEY || '',
  environment: process.env.APP_HONEYBADGER_ENV || process.env.UNION_DEPLOYMENT_ENV,
  developmentEnvironments: ['dev', 'development', 'development-ww', 'qa'],
  breadcrumbsEnabled: {
    dom: true,
    network: true,
    navigation: true,
    console: false,
  },
})

HB.beforeNotify((notice) => {
  if (notice?.message && IGNORED_MESSAGES.includes(notice.message)) return false
  return true
})

const honeybadger = HB

export default honeybadger
