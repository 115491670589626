import { ComponentType, createContext, PropsWithChildren, useEffect } from 'react'

import { ExperimentService } from './flipper.service'

import { Experiment, Variants } from './flipper.types'
import { trackExperimentStarted } from '@/utils/analytics/helpers'

export type FlipperContextProps = {
  experiments: Experiment[]
  variants: Variants
}

export const FlipperContext = createContext<FlipperContextProps>({
  experiments: ExperimentService.experiments(),
  variants: {},
})

type FlipperProviderProps = Partial<Pick<FlipperContextProps, 'variants'>>

export const FlipperProvider: ComponentType<PropsWithChildren<FlipperProviderProps>> = ({
  children,
  variants = {},
}) => {
  useEffect(() => {
    Object.values(variants)
      .filter((variant) => variant.firstTrigger)
      .forEach(({ extraData }) => {
        if (extraData) {
          trackExperimentStarted({
            'Experiment name': extraData.experimentName || '',
            'Variant name': extraData.variantName || '',
            'Trigger path': extraData.triggerPath || '',
          })
        }
      })
  }, [])

  return (
    <FlipperContext.Provider
      value={{
        experiments: ExperimentService.experiments(),
        variants,
      }}
    >
      {children}
    </FlipperContext.Provider>
  )
}
