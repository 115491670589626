import { LRUCache } from 'lru-cache'

// Strategy: keep the 24h most popular 1000 records
const cacheConfig = {
  max: 1000,
  ttlAutopurge: true,
  updateAgeOnGet: true,
  ttl: 3600 * 1000 * 24,
}

declare global {
  // eslint-disable-next-line no-var
  var lru: LRUCache<string, any>
}

if (!global.lru) {
  global.lru = new LRUCache(cacheConfig)
}
lru = global.lru

export default lru
