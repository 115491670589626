import { storefrontAPIFetch } from '@/domains/core/storefront/legacy'
import {
  DraftsMutationRemoveItemArgs,
  GetCartCounterQuery,
  GetCartDraftsCountersQuery,
  GetCartDraftsCountersQueryVariables,
  MoveToCartMutationVariables,
  MoveToDraftMutation,
  MoveToDraftMutationVariables,
  RemoveDraftMutation,
  SingleItemCartResult,
  UpsertDraftItemMutation,
  UpsertDraftItemMutationVariables,
} from '@/generated/graphql/graphql'
import type { IncomingHttpHeaders } from 'http'

import { DraftsCount, FindAllDrafts, MoveToCart, MoveToDraft, RemoveDraft, UpsertDraftItem } from '@/graphql/drafts'
import { CartCounter, CartDraftsCounters } from '@/graphql/general'
import { isTenant } from '@/utils/config'
import Honeybadger from '@honeybadger-io/js'
import type { FindAllDraftsForUser } from './drafts.types'

//Guest or WW user
async function getGuestCounters(headers?: IncomingHttpHeaders) {
  const response: GetCartCounterQuery = await storefrontAPIFetch<string, null>(CartCounter, null, headers)
  return { cart: response?.cart?.itemCount || 0, drafts: 0 }
}

async function getMemberCounters(variables: Record<'includeDrafts', boolean>, headers?: IncomingHttpHeaders) {
  const response: GetCartDraftsCountersQuery = await storefrontAPIFetch<string, GetCartDraftsCountersQueryVariables>(
    CartDraftsCounters,
    variables,
    headers
  )
  return { cart: response?.cart?.itemCount || 0, drafts: response?.drafts?.findAllForUser?.count || 0 }
}

export async function getCartDraftsCounters(includeDrafts: boolean, headers?: IncomingHttpHeaders) {
  let counters = { cart: 0, drafts: 0 }

  try {
    if (includeDrafts && isTenant('tk')) {
      const variables = { includeDrafts }
      counters = await getMemberCounters(variables, headers)
    } else {
      counters = await getGuestCounters(headers)
    }
  } catch (e: any) {
    Honeybadger.notify(e, {
      name: `getCartDraftsCountersError: ${e?.message}`,
      message: e?.message,
      context: { headers, includeDrafts },
    })
  } finally {
    // eslint-disable-next-line no-unsafe-finally
    return counters
  }
}

export async function fetchAllDrafts(headers: IncomingHttpHeaders): Promise<FindAllDraftsForUser | null> {
  try {
    const response = await storefrontAPIFetch<string, null>(FindAllDrafts, null, headers)
    return response?.drafts?.findAllForUser || {}
  } catch (e) {
    console.error(e)
    if (!(e instanceof Error)) return null
    Honeybadger.notify(e, {
      name: 'fetchAllDrafts @ modules/core/services/api/drafts.ts',
      message: e?.message,
    })
  }
  return null
}

export async function removeDraft(id: string, headers: IncomingHttpHeaders): Promise<RemoveDraftMutation | null> {
  try {
    const variables = {
      input: { itemId: id },
    }
    await storefrontAPIFetch<string, DraftsMutationRemoveItemArgs>(RemoveDraft, variables, headers)
  } catch (e) {
    if (!(e instanceof Error)) return null
    console.error(e)
    Honeybadger.notify(e, {
      name: 'moveToCart @ modules/core/services/api/drafts.ts',
      message: e?.message,
    })
  }
  return null
}

export async function moveDraftToCart(
  input: MoveToCartMutationVariables['input'],
  headers?: IncomingHttpHeaders
): Promise<SingleItemCartResult> {
  const variables = { input: input }

  const response = await storefrontAPIFetch(MoveToCart, variables, headers)

  return {
    cart: response.draftCart.moveDraftItemToCart.cart,
    item: response.draftCart.moveDraftItemToCart.item,
  }
}

export async function moveCartItemToDrafts(itemId: string): Promise<MoveToDraftMutation> {
  const variables = { itemId }

  return storefrontAPIFetch<string, MoveToDraftMutationVariables>(MoveToDraft, variables)
}

export async function getDraftItemCount(headers?: IncomingHttpHeaders): Promise<number> {
  try {
    const response = await storefrontAPIFetch<string, null>(DraftsCount, null, headers)
    return response.drafts?.findAllForUser.count || 0
  } catch (e) {
    console.error(e)
    if (!(e instanceof Error)) return 0
    Honeybadger.notify(e, {
      name: 'fetchAllDrafts @ modules/core/services/api/drafts.ts',
      message: e?.message,
    })
  }

  return 0
}

export async function upsertDraftItem(variables: UpsertDraftItemMutationVariables, headers?: IncomingHttpHeaders) {
  try {
    const response = (await storefrontAPIFetch<string, UpsertDraftItemMutationVariables>(
      UpsertDraftItem,
      variables,
      headers
    )) as UpsertDraftItemMutation

    return response.drafts.upsertItem
  } catch (e) {
    if (e instanceof Error) {
      Honeybadger.notify('Failed to upsert draft item', {
        message: e.message,
        context: {
          variables,
        },
      })
    }

    throw e
  }
}
