import { z } from 'zod'

export const MenuItem = z.object({
  text: z.string(),
  url: z.string(),
  linkAttributes: z.string().optional(),
  badge: z
    .object({
      text: z.string(),
    })
    .optional(),
})

export const BaseTreeItem = z.object({
  menuItem: MenuItem,
})

// For recursive types, see:
// https://github.com/colinhacks/zod?tab=readme-ov-file#recursive-types
export type TreeItem = z.infer<typeof BaseTreeItem> & {
  children?: TreeItem[]
}

export const TreeItem: z.ZodType<TreeItem> = BaseTreeItem.extend({
  children: z.lazy(() => z.array(TreeItem)).optional(),
})

export const Menu = z.object({
  code: z.string().default('subnav'),
  name: z.string().default('Subnav'),
  treeStructure: z.array(TreeItem).default([]),
})

export type Menu = z.infer<typeof Menu>
